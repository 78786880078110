<template>
  <q-card>
    <q-card-section class="q-pt-sm q-pb-none q-mb-none" horizontal>
      <q-card-section>
        <div class="text-overline">
          Акции
        </div>
      </q-card-section>

      <q-card-section class="q-pt-xs">
        <q-tabs v-model="statusFilter" dense>
          <q-tab icon="done_all" label="Все" name="" @click="() => onRequest()">
            <q-badge color="grey" floating v-text="rows.length"></q-badge>
          </q-tab>

          <q-tab icon="play_arrow" label="Одобрена" name="approved">
            <q-badge color="green" floating
                     v-text="rows.filter(({offer_status}) => offer_status === 'approved').length"></q-badge>
          </q-tab>

          <q-tab icon="mode_edit" label="Черновики" name="draft">
            <q-badge color="grey" floating
                     v-text="rows.filter(({offer_status}) => offer_status === 'draft').length"></q-badge>
          </q-tab>

          <q-tab icon="pending_actions" label="Модерация" name="pending">
            <q-badge color="orange" floating
                     v-text="rows.filter(({offer_status}) => offer_status === 'pending').length"></q-badge>
          </q-tab>

          <q-tab icon="archive" label="В архиве" name="archived">
            <q-badge color="grey" floating
                     v-text="rows.filter(({offer_status}) => offer_status === 'archived').length"></q-badge>
          </q-tab>

          <q-tab icon="thumb_down_alt" label="Отклонены" name="rejected">
            <q-badge color="grey" floating
                     v-text="rows.filter(({offer_status}) => offer_status === 'rejected').length"></q-badge>
          </q-tab>
        </q-tabs>
      </q-card-section>

      <q-space/>

      <q-card-section>
        <q-btn
          v-if="isMarketAdmin"
          :to="'/offers/addedit/' + entity.account_no"
          color="accent"
          icon="add"
          label="Добавить"
          outline
          size="sm"
          type="submit"
        ></q-btn>

      </q-card-section>
    </q-card-section>

    <q-card-section class="q-py-none">
      <div class="row full-width">
        <q-table
          v-model:pagination="pagination"
          :loading="loading"
          :rows="filteredRows"
          :rows-per-page-options="[3, 10, 50, 100, 1000]"
          class="full-width"
          flat
          separator="none"
          @request="onRequest"
        >
          <template v-slot:top></template>
          <template v-slot:top-row></template>
          <template v-slot:header></template>

          <template v-slot:body="props">
            <div class="full-width q-ma-xs q-pa-xs">
              <offer-item v-model="props.row" @updated="onRequest"></offer-item>
            </div>
          </template>

        </q-table>
      </div>
    </q-card-section>
  </q-card>

</template>

<script>
import { AdminApi } from '@/api/apis/admin-api.ts';
import { MarketApi } from '@/api/apis/market-api.ts';
// import { phoneToNumber } from '@/helpers/filters/phone';
import { debounce, Notify } from 'quasar';
import {
  computed, defineComponent, onMounted, ref, watch,
} from 'vue';
import { useStore } from 'vuex';
import OfferItem from './offers/item.vue';

export default defineComponent({
  name: 'OrganizationOffers',

  props: ['modelValue'],
  emits: ['update:modelValue'],

  components: {
    OfferItem,
  },

  setup(props, { emit }) {
    const store = useStore();
    const entity = computed(() => props.modelValue);
    const statusFilter = ref('');
    const rows = ref([]);
    const loading = ref(false);
    const filterName = ref('');
    const pagination = ref({
      sortBy: 'created_at',
      descending: true,
      page: 1,
      rowsPerPage: 10,
      rowsNumber: 10,
    });

    async function onRequest(params) {
      if (!entity.value?.account_no) {
        return;
      }

      params = params || {
        pagination: pagination.value,
      };

      const {
        page,
        rowsPerPage,
        sortBy,
        descending,
      } = params.pagination;

      // debugger;

      loading.value = true;

      const response = await new MarketApi().offerControllerGetOffers(
        (page - 1) * rowsPerPage,
        rowsPerPage,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        entity.value.account_no,
      );
      const { data } = response.data;
      pagination.value.rowsNumber = response.data.count;

      rows.value = data;

      pagination.value.page = page;
      pagination.value.rowsPerPage = rowsPerPage;
      pagination.value.sortBy = sortBy;
      pagination.value.descending = descending;

      loading.value = false;
    }

    onMounted(() => onRequest());

    const onRequestDebounced = debounce(onRequest, 500);
    watch(() => filterName.value, () => onRequestDebounced());
    watch(() => entity.value.account_no, () => onRequestDebounced());

    return {
      isMarketAdmin: computed(
        () => store.getters.rolesSet.has('market-admin') || store.getters.isAdmin,
      ),
      statusFilter,
      filterName,
      loading,
      pagination,
      rows,
      filteredRows: computed(
        () => rows.value.filter(
          ({ offer_status }) => !statusFilter.value || statusFilter.value === offer_status,
        ),
      ),

      console,
      onRequest,

      entity,

      async addEmployee(rsv_user_id) {
        try {
          await new MarketApi().offerControllerGetOffers(entity.value.account_no, rsv_user_id);
          Notify.create('Сохранено');

          onRequest();
        } catch (e) {
          console.error(e);
        }
      },

      async removeEmployee(orgId, rsv_user_id) {
        try {
          await new AdminApi().organizationControllerDeleteOrganizationEmployee(orgId, rsv_user_id);
          Notify.create('Удалено');

          onRequest();
        } catch (e) {
          console.error(e);
        }
      },

      async saveRoles(rsv_user_id) {
        try {
          await new AdminApi().organizationControllerPutOrganizationEmployee(
            entity.value.account_no, rsv_user_id,
          );
          Notify.create('Сохранено');

          onRequest();
        } catch (e) {
          console.error(e);
        }
      },
    };
  },
});
</script>
