<template>
  <q-card>
    <analytics-dialog v-model="analyticsDialogShow"
                      :account-id="currentEntity.account_no"
                      :filename="currentEntity.rsv_user_id + '_аналитика'"
                      :organization-id="currentEntity.organization_no"
    ></analytics-dialog>

    <q-card-section class="q-pt-sm q-pb-none q-mb-none" horizontal>
      <q-card-section class="q-pt-xs">
        <div class="text-overline">
          Сотрудники
        </div>
      </q-card-section>

      <q-card-section class="q-pt-xs">
        <q-input v-model="filterName"
                 borderless
                 clearable
                 dense
                 label="Поиск по Id сотрудника"
        >
          <template v-slot:prepend>
            <q-icon name="search"/>
          </template>
        </q-input>
      </q-card-section>

      <q-space/>

      <q-card-section class="q-pt-xs">
        <q-form v-if="isEmployeeAdmin">
          <q-input v-model="RsvUserIdForAdd"
                   borderless
                   dense
                   label="Id сотрудника"
                   @keydown.enter.prevent="() => addEmployee(RsvUserIdForAdd)"
          >
            <template v-slot:prepend>
              <q-icon name="phone"/>
            </template>

            <template v-slot:after>
              <q-btn
                color="accent"
                icon="add"
                label="Добавить"
                outline
                size="sm"
                type="submit"
                @click="() => addEmployee(RsvUserIdForAdd)"
              ></q-btn>
            </template>
          </q-input>
        </q-form>

      </q-card-section>
    </q-card-section>

    <q-card-section class="q-py-none">
      <div class="row full-width">
        <q-dialog v-model="qrCodeDialog">
          <q-card>
            <q-card-section class="row items-center q-pb-none">
              <div class="text-h6">QR-код сотрудника</div>
              <q-space/>
              <q-btn v-close-popup dense flat icon="close" round/>
            </q-card-section>

            <q-card-section>
              <canvas id="qr-employee-canvas"
                      height="250"
                      style="width: 250px; height: 250px"
                      width="250"></canvas>
            </q-card-section>
          </q-card>
        </q-dialog>

        <q-table
          v-model:pagination="pagination"
          :columns="columns"
          :loading="loading"
          :rows="rows"
          :rows-per-page-options="[3, 10, 50, 100, 1000]"
          class="full-width"
          flat
          @request="onRequest"
        >
          <template v-slot:top></template>
          <template v-slot:top-row></template>
          <template v-slot:header></template>

          <template v-slot:body-cell-info="props">
            <q-td :props="props" class="text-left">
              <q-avatar color="grey" icon="phone" size="md"></q-avatar>
              <span class="text-subtitle2 q-mx-md"
                    v-text="props.row.rsv_user_id"/>
              <span class="text-body2 q-mx-none text-grey" title="Создано">
                {{ $filters.toHumanDateTime(props.row.created_at) }}
              </span>
            </q-td>
          </template>

          <template v-slot:body-cell-action="props">
            <q-td :props="props" class="text-left">
              <q-btn
                v-if="isMarketCashier && props.row.roles.includes('market-cashier')"
                flat
                icon="qr_code_2"
                round
                @click="() => toggleQRCodeDialog(props.row.organization_no, props.row.account_no)"
              >
                <q-tooltip>QR-код сотрудника</q-tooltip>
              </q-btn>

              <q-btn v-if="isEmployeeAdmin" class="q-ma-md" icon="settings" round size="sm">
                <q-tooltip>Права сотрудника</q-tooltip>

                <q-menu cover>
                  <q-list class="q-ma-md">
                    <q-item>
                      <q-item-section class="text-subtitle2">Права сотрудника
                        {{ props.row.rsv_user_id }}
                      </q-item-section>
                    </q-item>
                    <q-separator/>

                    <q-item>
                      <q-item-section>
                        <q-toggle v-model="props.row.roles"
                                  color="secondary"
                                  label="Разрешить редактирование акций"
                                  val="market-admin"/>
                      </q-item-section>
                    </q-item>

                    <q-item>
                      <q-item-section>
                        <q-toggle v-model="props.row.roles"
                                  color="secondary"
                                  label="Разрешить управлять сотрудниками"
                                  val="employee-admin"/>
                      </q-item-section>
                    </q-item>

                    <q-item>
                      <q-item-section>
                        <q-toggle v-model="props.row.roles"
                                  color="secondary"
                                  label="Разрешить получение персонального QR-кода"
                                  val="market-cashier"/>
                      </q-item-section>
                    </q-item>

                    <q-item>
                      <q-item-section>
                        <q-toggle v-model="props.row.roles"
                                  color="secondary"
                                  label="Разрешить редактирование организации"
                                  val="organization-admin"/>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn>

              <q-btn v-if="isEmployeeAdmin"
                     class="q-ma-sm"
                     color="primary"
                     icon="analytics"
                     round
                     size="sm"
                     @click="() => {
                       analyticsDialogShow = !analyticsDialogShow;
                       currentEntity = props.row
                     }">
                <q-tooltip>Скачать аналитику</q-tooltip>
              </q-btn>

              <q-btn v-if="isEmployeeAdmin" class="q-ma-md" color="negative" icon="delete" round
                     size="sm">
                <q-tooltip>Удалить</q-tooltip>
                <confirm-menu
                  :ok="() => removeEmployee(props.row.organization_no, props.row.rsv_user_id)"/>
              </q-btn>

            </q-td>
          </template>

        </q-table>
      </div>
    </q-card-section>
  </q-card>

</template>

<script>
import { AdminApi } from '@/api/apis/admin-api.ts';
import ConfirmMenu from '@/components/forms/confirmMenu';
import AnalyticsDialog from '@/components/organizations/analyticsDialog';
import { phoneToNumber } from '@/helpers/filters/phone';
import QRCode from 'qrcode';
import { debounce, Notify } from 'quasar';
import {
  computed, defineComponent, nextTick, onMounted, ref, watch,
} from 'vue';
import { useStore } from 'vuex';

const columns = [
  {
    name: 'info',
    label: '',
    align: 'left',
  },
  {
    name: 'action',
    label: '',
    align: 'left',
  },
];

export default defineComponent({
  name: 'OrganizationEmployee',

  props: ['modelValue'],
  emits: ['update:modelValue'],

  components: {
    ConfirmMenu,
    AnalyticsDialog,
  },

  setup(props, { emit }) {
    const store = useStore();
    const entity = computed(() => props.modelValue);
    const RsvUserIdForAdd = ref('');
    const qrCodeDialog = ref(false);

    const rows = ref([]);

    const loading = ref(false);
    const filterName = ref('');
    const pagination = ref({
      sortBy: 'created_at',
      descending: true,
      page: 1,
      rowsPerPage: 10,
      rowsNumber: 10,
    });

    const rowWatchers = [];

    async function onRequest(params) {
      if (!entity.value?.account_no) {
        return;
      }

      params = params || {
        pagination: pagination.value,
      };

      const {
        page,
        rowsPerPage,
        sortBy,
        descending,
      } = params.pagination;

      // debugger;

      loading.value = true;

      let phoneFilter;

      if (phoneToNumber(filterName.value).length === 11) {
        phoneFilter = [phoneToNumber(filterName.value)];
      }

      const response = await new AdminApi().organizationControllerGetOrganizationEmployees(
        entity.value.account_no,
        (page - 1) * rowsPerPage,
        rowsPerPage,
        undefined,
        phoneFilter,
      );
      const { data } = response.data;
      pagination.value.rowsNumber = response.data.count;

      rows.value = data.filter(
        (item) => !filterName.value || item.rsv_user_id.has(phoneToNumber(filterName.value)),
      );

      pagination.value.page = page;
      pagination.value.rowsPerPage = rowsPerPage;
      pagination.value.sortBy = sortBy;
      pagination.value.descending = descending;

      loading.value = false;

      rowWatchers.forEach((w) => w());

      for (const index in rows.value) {
        rowWatchers.push(
          watch(
            () => rows.value[index].roles,
            async (value, oldValue) => {
              const remove = oldValue.filter((v) => !value.includes(v));
              const add = value.filter((v) => !oldValue.includes(v));

              if (remove.length) {
                await new AdminApi().organizationControllerDeleteOrganizationEmployeeRoles(
                  remove,
                  rows.value[index].organization_no,
                  rows.value[index].rsv_user_id,
                );
              }

              if (add.length) {
                await new AdminApi().organizationControllerPostOrganizationEmployeeRole(
                  add,
                  rows.value[index].organization_no,
                  rows.value[index].rsv_user_id,
                );
              }
            },
          ),
        );
      }
    }

    onMounted(() => onRequest());

    const onRequestDebounced = debounce(onRequest, 500);
    watch(() => filterName.value, () => onRequestDebounced());
    watch(() => entity.value.account_no, () => onRequestDebounced());

    return {
      isEmployeeAdmin: computed(
        () => store.getters.rolesSet.has('employee-admin') || store.getters.isAdmin,
      ),
      isMarketCashier: computed(
        () => store.getters.rolesSet.has('market-cashier') || store.getters.isAdmin,
      ),
      analyticsDialogShow: ref(false),
      currentEntity: ref({}),
      RsvUserIdForAdd,
      filterName,
      loading,
      pagination,
      columns,
      rows,
      console,
      onRequest,
      qrCodeDialog,

      entity,

      async toggleQRCodeDialog(vendor_no, employee_no) {
        if (qrCodeDialog.value) {
          qrCodeDialog.value = false;
          return;
        }

        qrCodeDialog.value = true;

        await nextTick();

        QRCode.toCanvas(document.getElementById('qr-employee-canvas'),
          JSON.stringify({
            vendor_no,
            employee_no,
          }),
          {
            width: 250,
            height: 250,
          },
          (error) => {
            if (error) console.error(error);
          });
      },

      async addEmployee(phone) {
        try {
          await new AdminApi().organizationControllerPutOrganizationEmployee(
            entity.value.account_no, phoneToNumber(phone),
          );
          Notify.create('Сохранено');

          onRequest();
        } catch (e) {
          console.error(e);
        }
      },

      async removeEmployee(orgId, phone) {
        try {
          await new AdminApi().organizationControllerDeleteOrganizationEmployee(orgId, phone);
          Notify.create('Удалено');

          onRequest();
        } catch (e) {
          console.error(e);
        }
      },

      async saveRoles(phone) {
        try {
          await new AdminApi().organizationControllerPutOrganizationEmployee(
            entity.value.account_no, phoneToNumber(phone),
          );
          Notify.create('Сохранено');

          onRequest();
        } catch (e) {
          console.error(e);
        }
      },
    };
  },
});
</script>
